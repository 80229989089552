import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'usp-questions',
  templateUrl: './usp-questions.component.html',
  styleUrls: ['./usp-questions.component.scss']
})
export class UspQuestionsComponent implements OnInit {
  @Input() faqsInfo: any = {};
  columns = ['Links'];
  helpCenterURL = environment?.helpCenterURL;

  constructor(private languageService: LanguageService) { }

  ngOnInit(): void {}

  openFaqUrl(url: string): void {
    if (url) {
      window.open(`${this.helpCenterURL}${url}?userLang=` + this.languageService.currentLanguage, '_blank');
    }
  }

}
