import { Component, EventEmitter, OnInit, SecurityContext } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FormLabels, IncidentCategory, Notifications, PopUpContent, ServiceCategory, TicketAttachment } from '../../shared/shared.interfaces';
import { TicketsService } from '../../services/tickets.service';
import { TicketCategoryService } from '../../services/ticket-category.service';
import { Router } from '@angular/router';
import { TokenService } from '../../services/token.service';
import { CREATE_TICKET_DIALOG, FORM_VALIDATION, SERVICE_CATEGORY_ID } from '../../shared/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { UspPopupComponent } from '../../shared/components/usp-popup/usp-popup.component';
import { AuthService } from '../../shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'usp-create-new-ticket',
  templateUrl: './usp-create-new-ticket.component.html',
  styleUrls: ['./usp-create-new-ticket.component.scss']
})
export class UspCreateNewTicketComponent implements OnInit {

  createTicketForm: FormGroup;
  formLabels: FormLabels = {
    serviceCategory: '',
    incidentCategory: '',
    subject: '',
    description: '',
    vemItemID: '',
  };
  attachments: Array<TicketAttachment> = [];
  serviceCategoryID = '';
  incidentCategoryID = '';
  notifications: Notifications;
  showErrorNotification = false;
  SERVICE_CATEGORY_OPTIONS: Array<ServiceCategory> = [];
  INCIDENT_CATEGORY_OPTIONS: Array<IncidentCategory> = [];
  removeFiles: EventEmitter<boolean> = new EventEmitter();
  showErrorMessages = false;
  isEmptyCategories = true;
  formSubmitAttempt = false;
  showVemItemID = false;

  constructor(
    public translateService: TranslateService,
    private ticketService: TicketsService,
    public ticketCategoryService: TicketCategoryService,
    public router: Router,
    public tokenService: TokenService,
    public sanitizer: DomSanitizer,
    public popUpAfterSubmit: MatDialog,
    private authService: AuthService,
  ) {
    // redirect to iESM Support portal
    window.location.href = environment.iESMSupportURL;

    this.translateService.get('CREATE.TICKET.FORM')
      .subscribe(() => {
        this.formLabels = {
          serviceCategory: this.translateService.instant('CREATE.TICKET.FORM.SERVICE.CATEGORY.PLACEHOLDER'),
          incidentCategory: this.translateService.instant('CREATE.TICKET.FORM.INCIDENT.CATEGORY.PLACEHOLDER'),
          subject: this.translateService.instant('CREATE.TICKET.FORM.SUBJECT.PLACEHOLDER'),
          description: this.translateService.instant('CREATE.TICKET.FORM.DESCRIPTION.PLACEHOLDER'),
          vemItemID: this.translateService.instant('CREATE.TICKET.FORM.VEM.ITEM.ID.PLACEHOLDER'),
        };
        this.notifications = {
          error: this.translateService.instant('CREATE.TICKET.NOTIFICATIONS.ERROR.SUBMIT.MESSAGE')
        };
      });
  }

  ngOnInit(): void {
    this.authService.fetchUser();
    this.createTicketForm = new FormGroup({
      serviceCategory: new FormControl('', Validators.required),
      incidentCategory: new FormControl({value: '', disabled: true}, Validators.required),
      subject: new FormControl('', Validators.required),
      description: new FormControl('', Validators.required),
      vemItemID: new FormControl('', Validators.maxLength(FORM_VALIDATION.VEM_ITEM_ID_LENGTH))
    });
    this.getTicketCategories();
    this.tokenService.generateCSRF();
  }

  getTicketCategories(): void {
    this.ticketCategoryService.getTicketCategories().subscribe(
      res => {
        res.value.sort((a, b) => a.Name > b.Name ? 1 : -1);
        this.SERVICE_CATEGORY_OPTIONS = res.value;
      },
      err => console.log(err)
    );
  }

  onChangeServiceCategory(options: string[]): void {
    this.serviceCategoryID = options[0];
    this.incidentCategoryID = '';
    const result = this.SERVICE_CATEGORY_OPTIONS.filter(i => i.ID === this.serviceCategoryID);
    this.INCIDENT_CATEGORY_OPTIONS = result[0].IncidentCategories.sort((a, b) => a.Name > b.Name ? 1 : -1);

    if (this.serviceCategoryID) {
      this.createTicketForm.controls.incidentCategory.enable();
    }

    if (!this.incidentCategoryID) {
      this.isEmptyCategories = true;
    }

    this.setServiceCategoryDescription(this.serviceCategoryID);
  }

  setServiceCategoryDescription(id: string): void {
    this.ticketCategoryService.getServiceCategoryDescription().subscribe(
      res => {
        const description = res.value.filter(i => i.ID === id)[0].Description;
        this.createTicketForm.patchValue({description});
      }
    );

    this.showVemItemID = this.serviceCategoryID === SERVICE_CATEGORY_ID.VIRTUAL_EVENT_SERVICES;
  }

  onChangeIncidentCategory(options: string[]): void {
    this.incidentCategoryID = options[0];

    if (this.incidentCategoryID && this.serviceCategoryID) {
      this.isEmptyCategories = false;
    }

  }

  getAttachments(att: TicketAttachment[]): void {
    this.attachments = att;
  }

  onFormSubmitAttempt(): void {
    this.formSubmitAttempt = true;
  }

  setValidationClass(field: string): string {
    return (this.createTicketForm.controls[field].invalid &&
      (this.formSubmitAttempt || this.createTicketForm.controls[field].touched)) ? 'input-field-invalid' : '';
  }

  setFieldAsTouched(field: string): void {
    this.createTicketForm.controls[field].markAsTouched();
  }

  setFieldAsDirty(field: string): void {
    this.createTicketForm.controls[field].markAsDirty();
  }

  onSubmitForm(): void {
    this.formSubmitAttempt = true;

    if (this.createTicketForm.valid) {
      const sanitizedValueName = this.sanitizer.sanitize(SecurityContext.HTML, this.createTicketForm.controls.subject.value)
        .replace(/&#10;|&amp;#10;/g, '<br>').replace(/amp;/g, '');
      const sanitizedValueDescription = this.sanitizer.sanitize(SecurityContext.HTML, this.createTicketForm.controls.description.value)
        .replace(/&#10;|&amp;#10;/g, '<br>').replace(/amp;/g, '');
      const sanitizedValueVEMItemID = this.sanitizer.sanitize(SecurityContext.HTML, this.createTicketForm.controls.vemItemID.value);
      const ticket = {
        Name: sanitizedValueName,
        ServiceIssueCategoryID: this.serviceCategoryID,
        IncidentServiceIssueCategoryID: this.incidentCategoryID,
        ServiceRequestDescription:
          {
            Text: sanitizedValueDescription
          },
        ServiceRequestAttachmentFolder: this.attachments,
        VEMItemID_KUT: sanitizedValueVEMItemID
      };
      this.ticketService.sendNewTicket(ticket).subscribe(
        response => this._completeSubmitSuccess(response.value),
        error => {
          console.log(error);
          this.showErrorNotification = true;
          window.scrollTo(0, 0);
        }
      );
    }
  }

  _completeSubmitSuccess(response: string): void {
    const message = `The ticket <b>#${response}</b> was successfully sent.`;
    localStorage.setItem('successNotification', message);
    window.scrollTo(0, 0);
    const data: PopUpContent = {
      title: CREATE_TICKET_DIALOG.TITLE,
      text: CREATE_TICKET_DIALOG.TEXT,
      confirmButton: CREATE_TICKET_DIALOG.CONFIRM_BUTTON,
      cancelButton: CREATE_TICKET_DIALOG.CANCEL_BUTTON
    };
    this.popUpAfterSubmit.open(UspPopupComponent, {
      panelClass: 'custom-dialog-container',
      backdropClass: 'custom-backdrop',
      data
    }).afterClosed().subscribe(
      () => document.location.reload()
    );
  }

  onCancelSubmitForm(): void {
    this.createTicketForm.reset();
    this.removeFiles.emit(true);
    this.formSubmitAttempt = false;
    this.router.navigateByUrl('/');
  }

}
