<div class="usp-home usp-wrapper">
  <div class="usp-main-content-wrapper">
    <usp-additional-info [info]="additionalInfo"></usp-additional-info>
    <ds-message *ngIf="showSuccessNotification"
                type="success"
                [closable]="true"
                (canBeClosed)="onCloseNotification()"
                [message]="successNotification">
    </ds-message>
    <usp-faqs></usp-faqs>
  </div>
</div>
