import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'usp-attached-file',
  templateUrl: './usp-attached-file.component.html',
  styleUrls: ['./usp-attached-file.component.scss']
})
export class UspAttachedFileComponent implements OnInit {

  @Input() name = '';
  isLoading = true;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.isLoading = false;
      }, 1600
    );
  }

}
