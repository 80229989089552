import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { APIConst } from '../shared/shared.enum';
import { REQUEST_QUERY } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {
  queryLanguage = REQUEST_QUERY.LANGUAGE;
  useMockData = false;

  constructor(
    private http: HttpClient,
  ) { }

  getFAQs(language): Observable<any> {
    if (this.useMockData) {
      return this.http.get('assets/mock/faqs.json');
    }

    return this.http.get(`${APIConst.cms}${APIConst.allFaq}(${this.queryLanguage}='${language}')`);
  }
}
