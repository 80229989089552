import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ERROR_STATUS } from '../shared/constants';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    const getDataReq = request.clone({
      headers: request.headers.set('Content-Type', 'application/json'),
    });

    return next.handle(getDataReq).pipe(
      tap(
        (event) => {

          if (event instanceof HttpResponse) {
            console.log('Server response');
          }

        },
        (err) => {

          if (err instanceof HttpErrorResponse) {
            if (err.status === ERROR_STATUS.HTTP_ERROR_RESPONSE) {
              console.log('Error response');
            }
          }

        }
      )
    );
  }

}
