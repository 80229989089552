import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { APIConst } from '../shared/shared.enum';
import { TicketByID, TicketList } from '../shared/shared.interfaces';
import { REQUEST_QUERY } from '../shared/constants';
import { ODataServiceFactory } from 'angular-odata';
import { ODataEntitySetResource } from 'angular-odata/lib/resources';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  private odataAllTickets: ODataEntitySetResource<TicketList>;
  private odataOpenTickets: ODataEntitySetResource<TicketList>;
  private odataCompletedTickets: ODataEntitySetResource<TicketList>;
  private queryId = REQUEST_QUERY.ID;

  constructor(
    private http: HttpClient,
    private odataFactory: ODataServiceFactory
  ) {
    this.odataAllTickets = this.odataFactory.entitySet<TicketList>(`${APIConst.api}${APIConst.allTickets}`).entities();
    this.odataOpenTickets = this.odataFactory.entitySet<TicketList>(`${APIConst.api}${APIConst.openTickets}`).entities();
    this.odataCompletedTickets = this.odataFactory.entitySet<TicketList>(`${APIConst.api}${APIConst.completedTickets}`).entities();
  }

  sendNewTicket(ticket): Observable<any> {
    return this.http.post(`${APIConst.api}${APIConst.createTicket}`, ticket);
  }

  getAllTickets(): Observable<{ entities: TicketList[] }> {
    return this.odataAllTickets.fetch();
  }

  getOpenTickets(): Observable<{ entities: TicketList[] }> {
    return this.odataOpenTickets.fetch();
  }

  getCompletedTickets(): Observable<{ entities: TicketList[] }> {
    return this.odataCompletedTickets.fetch();
  }

  getTicketByID(id: number): Observable<TicketByID> {
    return this.http.get<TicketByID>(`${APIConst.api}${APIConst.ticketById}(${this.queryId}='${id}')`);
  }

  closeTicket(reply): Observable<any> {
    return this.http.post(`${APIConst.api}${APIConst.closeTicket}`, reply);
  }

  updateTicket(reply): Observable<any> {
    return this.http.post(`${APIConst.api}${APIConst.updateTicket}`, reply);
  }

  getMockTicketByID(): Observable<any> {
    return this.http.get('assets/mock/ticket-id.json');
  }

  getMockOpenTickets(): Observable<any> {
    return this.http.get('assets/mock/all-tickets.json');
  }

  getTicketStatusDescription(): Observable<any> {
    return this.http.get('assets/tooltip-text.json');
  }
}
