import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SecurityContext, ViewChild } from '@angular/core';
import { AttachmentCollection, TicketAttachment } from '../../shared.interfaces';
import { FORM_VALIDATION } from '../../constants';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'usp-attachment',
  templateUrl: './usp-attachment.component.html',
  styleUrls: ['./usp-attachment.component.scss']
})
export class UspAttachmentComponent implements OnInit {

  @ViewChild('fileDropRef', {static: false}) fileDropEl: ElementRef;
  @Output() sendNewAttachments = new EventEmitter<TicketAttachment[]>();
  @Input() removeFiles = new EventEmitter<boolean>();
  @Input() previousCustomerAttachments: AttachmentCollection[] = [];
  @Input() agentAttachments: AttachmentCollection[] = [];
  files: any[] = [];
  newAttachments: TicketAttachment[] = [];
  notAcceptableFileTypes = FORM_VALIDATION.FORBIDDEN_FILES_TYPES;
  fileInvalid = false;

  constructor(
    public sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {

    if (this.removeFiles) {
      this.removeFiles.subscribe(() => {
        this.files = [];
        this.newAttachments = [];
      });
    }

  }

  activateInputFile(): void {
    this.fileDropEl.nativeElement.click();
  }

  // handle file from browsing
  onFileBrowseHandler($event): void {
    const sanitizedValue = this.sanitizer.sanitize(SecurityContext.NONE, $event.target.files);
    this.fileValidation(sanitizedValue);
  }

  // validation of files
  fileValidation(files): void {
    this.fileInvalid = false;
    const file = files[0];
    const fileType = file.name.substr(file.name.lastIndexOf('.') + 1);

    if (files && file) {
      // validation of type and size
      if (!this.notAcceptableFileTypes.includes(fileType) && file.size < FORM_VALIDATION.FILE_SIZE) {
        this.prepareFilesList(files);
      } else {
        this.fileInvalid = true;
      }
    }

  }

  setValidationClass(): string {
    if (this.fileInvalid) {
      return 'attachment-container-error';
    }
  }

  // Delete file from files list
  onDeleteFile(index: number): void {
    this.files.splice(index, 1);
    this.newAttachments.splice(index, 1);
    this.sendNewAttachments.emit(this.newAttachments);
  }

  // convert files to base64
  convertFilesToBlob(files: Array<any>): void {
    for (const item of files) {
      const reader = new FileReader();
      reader.readAsDataURL(item);
      reader.onload = () => {
        const base64String = reader.result.toString().replace('data:', '').replace(/^.+,/, '');
        const convertedItem: TicketAttachment = {
          Binary: base64String,
          Name: item.name,
        };
        this.newAttachments.push(convertedItem);
      };
    }
    this.sendNewAttachments.emit(this.newAttachments);
  }

  // Convert Files list to normal array list
  prepareFilesList(files: Array<any>): void {
    this.files = [];
    for (const item of files) {
      this.files.push(item);
    }
    this.fileDropEl.nativeElement.value = '';
    this.convertFilesToBlob(this.files);
  }

  // format bytes
  //  @param bytes (File size in bytes)
  //  @param decimals (Decimals point)
  formatBytes(bytes, decimals = 2): string {

    if (bytes === 0) {

      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onDownloadFile(fileName: string, fileBinary: string): void {
    const padding = '='.repeat((4 - fileBinary.length % 4) % 4);
    const base64 = (fileBinary + padding).replace(/-/g, '+').replace(/_/g, '/');
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const binaryData = [];
    binaryData.push(byteArray);
    this.generateDownloadLink(fileName, binaryData);
   }

  generateDownloadLink(fileName: string, binaryData: BlobPart[]): void {
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(
      new Blob(binaryData, { type: 'blob' }));
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

}

