<div class="usp-home-additional-information">
    <div class="ds-grid">
      <div class="row col-highlight">
        <div class="col col__highlight text">
          <h1>{{ "HOME.ENTRY.TITLE" | translate }}</h1>
          <p>{{info.text}}</p>
        </div>
        <div class="col image">
        </div>
      </div>
    </div>
</div>
