import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIConst } from '../shared/shared.enum';

@Injectable({
  providedIn: 'root'
})
export class TicketCategoryService {

  constructor(
    private http: HttpClient
  ) { }

  getTicketCategories(): Observable<any> {
    return this.http.get(`${APIConst.api}${APIConst.ticketCategories}`);
  }

  getMockTicketCategories(): Observable<any> {
    return this.http.get('assets/mock/categories.json');
  }

  getServiceCategoryDescription(): Observable<any> {
    return this.http.get('assets/categories-description.json');
  }

}
