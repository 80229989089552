import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private cookieKey = 'userLang';
  defaultLanguage = 'EN';

  constructor(
    private cookieService: CookieService
  ) { }

  get currentLanguage(): string {
    return this.cookieService.get(this.cookieKey) || this.defaultLanguage;
  }

}
