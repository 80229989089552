<ngu-carousel #myCarousel [inputs]="carouselConfig" [dataSource]="sanitizedTextList">
  <div *nguCarouselDef="let item;" class="item">
    <div class="tile">
      <ul>
        <li *ngFor="let li of item"
            [innerHtml]="li | sanitize: 'html'">
        </li>
      </ul>
    </div>
  </div>
  <ul class="myPoint" NguCarouselPoint>
    <li *ngFor="let c of myCarousel.pointNumbers; let i = index" [class.active]="i==myCarousel.activePoint" (click)="myCarousel.moveTo(i)"></li>
  </ul>
</ngu-carousel>
