<label
  class="label"
  [ngClass]="setValidationClass()">
{{ "CREATE.TICKET.FORM.ATTACHMENT.LABEL" | translate }}</label>
<div
      class="attachment-container"
     [ngClass]="setValidationClass()"
     uspDnd
     (fileDropped)="fileValidation($event)">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    multiple
    (change)="onFileBrowseHandler($event)" />
    <h3>{{ "CREATE.TICKET.FORM.ATTACHMENT.PLACEHOLDER.PART_1" | translate }}</h3>
    <p>{{ "CREATE.TICKET.FORM.ATTACHMENT.PLACEHOLDER.PART_2" | translate }}</p>
    <button class="ds-button ds-button--primary mr-4 mb-4 focusable focusable__element button-choose-file"
            type="button"
            (click)="activateInputFile()">
      {{ "CREATE.TICKET.FORM.ATTACHMENT.BUTTON" | translate }}
    </button>
 </div>

<div class="error-message">
  <p *ngIf="fileInvalid">{{ "CREATE.TICKET.ERROR.MESSAGES.INVALID.TYPE.OR.SIZE.FILE" | translate }}</p>
</div>

<div class="files-list" *ngIf="newAttachments.length">
  <div class="single-file"
    *ngFor="let file of newAttachments; let i = index">
    <usp-attached-file [name]="file?.Name"></usp-attached-file>
    <span class="action-file" (click)="onDeleteFile(i)">
     {{ "CREATE.TICKET.ATTACHMENTS.REMOVE.FILE" | translate }}
    </span>
  </div>
</div>

<div class="files-list" *ngIf="previousCustomerAttachments.length">
  <span class="files-list__label">Previous Attachments</span>
  <div class="single-file"
       *ngFor="let file of previousCustomerAttachments; let i = index">
    <usp-attached-file [name]="file?.Name"></usp-attached-file>
    <span class="action-file" (click)="onDownloadFile(file?.Name, file?.Binary)">
     {{ "CREATE.TICKET.ATTACHMENTS.DOWNLOAD.FILE" | translate }}
    </span>
  </div>
</div>

<div class="files-list" *ngIf="agentAttachments.length">
  <span class="files-list__label">Attachments from Support Agent</span>
  <div class="single-file"
       *ngFor="let file of agentAttachments; let i = index">
    <usp-attached-file [name]="file?.Name"></usp-attached-file>
    <span class="action-file" (click)="onDownloadFile(file?.Name, file?.Binary)">
     {{ "CREATE.TICKET.ATTACHMENTS.DOWNLOAD.FILE" | translate }}
    </span>
  </div>
</div>
