import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesPathConfig } from '../../shared.enum';

@Component({
  selector: 'usp-breadcrumbs',
  templateUrl: './usp-breadcrumbs.component.html',
  styleUrls: ['./usp-breadcrumbs.component.scss']
})
export class UspBreadcrumbsComponent implements OnInit {

  constructor(
    public router: Router,
  ) { }

  ngOnInit(): void {
  }

  onNavigate(): void {
    this.router.navigateByUrl(`/${RoutesPathConfig.allTickets}`);
  }

}
