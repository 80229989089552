import { IEnvironment } from 'src/app/shared/shared.interfaces';

export const environment: IEnvironment = {
  dev: false,
  test: true,
  production: false,
  defaultLocale: 'en',
  lxpWebComponentsLink: '/web-components.js',
  certificationHubURL: 'https://accounts400.sap.com/saml2/idp/usso/questionmark?sp=sapcertification.questionmark.eu/612099/ext/simplesaml',
  helpCenterURL: 'https://lhub-home-approuter-t35t.cfapps.eu10.hana.ondemand.com/help-center/faq/',
  iESMSupportURL: 'https://lso-ssrv-app-test.cfapps.eu10-004.hana.ondemand.com'
};
