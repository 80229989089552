import { Component, Input, OnInit } from '@angular/core';
import { StatusTicketDescription, TicketList } from '../../shared.interfaces';
import { Router } from '@angular/router';
import { DeviceDetectorService } from '../../services/device-detector.service';
import { RoutesPathConfig } from '../../shared.enum';
import { TicketsService } from '../../../services/tickets.service';


@Component({
  selector: 'usp-table-all-tickets',
  templateUrl: './usp-table-all-tickets.component.html',
  styleUrls: ['./usp-table-all-tickets.component.scss']
})
export class UspTableAllTicketsComponent implements OnInit {

  @Input() tickets: Array<TicketList>;
  columns = ['TicketID', 'Subject', 'Status', 'CreatedOn'];
  isMobile: boolean = this.deviceDetectorService.isMobile;
  isTablet: boolean = this.deviceDetectorService.isTablet;
  isDesktop: boolean = this.deviceDetectorService.isDesktop;
  statusTicketDescription: Array<StatusTicketDescription>;
  tooltipText: string;

  constructor(
    private router: Router,
    private deviceDetectorService: DeviceDetectorService,
    private ticketService: TicketsService
  ) { }

  ngOnInit(): void {
    this.ticketService.getTicketStatusDescription().subscribe(
      res => this.statusTicketDescription = res.value
    );
  }

  openCertainTicket(ID: string): void {
    this.router.navigateByUrl(`${RoutesPathConfig.allTickets}/${RoutesPathConfig.ticket}/` + ID).then();
  }

  onTooltipHover($event): void {
    let ticketStatus = $event.target.innerText.toLowerCase().trim();

    if (!ticketStatus) {
      ticketStatus = $event.path[1].innerText.toLowerCase().trim();
    }

    this.tooltipText = this.statusTicketDescription.filter(i => i.status === ticketStatus)[0].text;
  }

}
