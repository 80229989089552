<usp-customer-info></usp-customer-info>
<div class="usp-create-new-ticket usp-main-content-wrapper">

  <ds-message *ngIf="showErrorNotification"
              [message]="notifications.error"
              type="error"
              [closable]="true">
  </ds-message>

  <h1>{{ "CREATE.TICKET.FORM.TITLE" | translate }}</h1>
  <form class="form" [formGroup]="createTicketForm" (ngSubmit)="onSubmitForm()">

    <div class="wrapper-input">
      <label for="serviceCategory">{{ "CREATE.TICKET.FORM.SERVICE.CATEGORY.LABEL" | translate }}</label>
      <ds-combo-box
        formControlName="serviceCategory"
        id="serviceCategory"
        class="input-field"
        [ngClass]="setValidationClass('serviceCategory')"
        [label]="formLabels.serviceCategory | translate"
        (valueChange)="onChangeServiceCategory($event)"
        (focusin)="setFieldAsTouched('serviceCategory')"
        (focusout)="setFieldAsDirty('serviceCategory')">
        <ds-combo-box-dropdown [showSearchBar]="false">
          <ds-combo-box-dropdown-group class="dropdown-group">
            <ds-combo-box-dropdown-option *ngFor="let option of SERVICE_CATEGORY_OPTIONS"
                                          [label]="option.Name | translate"
                                          [value]="option.ID">
              <div>
                <span [innerHTML]="option.Name | translate"></span>
              </div>
            </ds-combo-box-dropdown-option>
          </ds-combo-box-dropdown-group>
        </ds-combo-box-dropdown>
      </ds-combo-box>
      <div *ngIf="createTicketForm.controls.serviceCategory.invalid && (formSubmitAttempt || createTicketForm.controls.serviceCategory.dirty)"
           class="error-message">
        {{ "CREATE.TICKET.ERROR.MESSAGES.INVALID.REQUIRED.INPUT" | translate }}
      </div>
    </div>

    <div class="wrapper-input">
      <label for="incidentCategory">{{ "CREATE.TICKET.FORM.INCIDENT.CATEGORY.LABEL" | translate }}</label>
      <ds-combo-box
        formControlName="incidentCategory"
        id="incidentCategory"
        class="input-field"
        [ngClass]="setValidationClass('incidentCategory')"
        [label]="formLabels.incidentCategory | translate"
        (valueChange)="onChangeIncidentCategory($event)"
        (focusin)="setFieldAsTouched('incidentCategory')"
        (focusout)="setFieldAsDirty('incidentCategory')">
        <ds-combo-box-dropdown [showSearchBar]="false">
          <ds-combo-box-dropdown-group class="dropdown-group">
            <ds-combo-box-dropdown-option *ngFor="let option of INCIDENT_CATEGORY_OPTIONS"
                                          [label]="option.Name | translate"
                                          [value]="option.ID">
              <div>
                <span [innerHTML]="option.Name | translate"></span>
              </div>
            </ds-combo-box-dropdown-option>
          </ds-combo-box-dropdown-group>
        </ds-combo-box-dropdown>
      </ds-combo-box>
      <div *ngIf="createTicketForm.controls.incidentCategory.invalid && (formSubmitAttempt || createTicketForm.controls.incidentCategory.dirty)"
           class="error-message">
        {{ "CREATE.TICKET.ERROR.MESSAGES.INVALID.REQUIRED.INPUT" | translate }}
      </div>
    </div>

    <div class="wrapper-input">
      <label for="subject">{{ "CREATE.TICKET.FORM.SUBJECT.LABEL" | translate }}</label>
      <ds-input
        formControlName="subject"
        id="subject"
        class="input-field"
        [ngClass]="setValidationClass('subject')"
        [label]="formLabels.subject | translate"
        [placeholder]="formLabels.subject | translate"
        type="text"
        hidePlaceholderWhenFilled="true"
        hidePlaceholderWhenFocused="true"
        (focusin)="setFieldAsTouched('subject')"
        (focusout)="setFieldAsDirty('subject')"
      >
      </ds-input>
      <div *ngIf="createTicketForm.controls.subject.invalid && (formSubmitAttempt || createTicketForm.controls.subject.dirty)"
           class="error-message">
        {{ "CREATE.TICKET.ERROR.MESSAGES.INVALID.REQUIRED.INPUT" | translate }}
      </div>
    </div>

    <div class="wrapper-input">
      <label for="description">{{ "CREATE.TICKET.FORM.DESCRIPTION.LABEL" | translate }}</label>
      <ds-textarea
        formControlName="description"
        id="description"
        class="input-field"
        [ngClass]="setValidationClass('description')"
        [showErrorMessages]="showErrorMessages"
        [label]="formLabels.description | translate"
        [placeholder]="formLabels.description | translate"
        (focusin)="setFieldAsTouched('description')"
        (focusout)="setFieldAsDirty('description')"
      ></ds-textarea>
      <div *ngIf="createTicketForm.controls.description.invalid && (formSubmitAttempt || createTicketForm.controls.description.dirty)"
           class="error-message">
        {{ "CREATE.TICKET.ERROR.MESSAGES.INVALID.REQUIRED.INPUT" | translate }}
      </div>
    </div>

    <div class="wrapper-input" *ngIf="showVemItemID">
      <label for="vemItemID">{{ "CREATE.TICKET.FORM.VEM.ITEM.ID.LABEL" | translate }}</label>
      <ds-input
        formControlName="vemItemID"
        id="vemItemID"
        class="input-field"
        [ngClass]="setValidationClass('vemItemID')"
        [label]="formLabels.vemItemID | translate"
        [placeholder]="formLabels.vemItemID | translate"
        type="text"
        hidePlaceholderWhenFilled="true"
        hidePlaceholderWhenFocused="true"
        (focusin)="setFieldAsTouched('vemItemID')"
        (focusout)="setFieldAsDirty('vemItemID')">
      </ds-input>
      <div *ngIf="createTicketForm.controls.vemItemID.invalid && (formSubmitAttempt || createTicketForm.controls.vemItemID.dirty)"
           class="error-message">
        Value can't be more than 40 symbols
      </div>
    </div>

    <usp-attachment (sendNewAttachments)="getAttachments($event)"
                    [removeFiles]="removeFiles">
    </usp-attachment>

    <div class="buttons">
      <div class="submit-invalid-wrapper"
           (click)="onFormSubmitAttempt()"
           *ngIf="createTicketForm.invalid">
        <span class="submit-invalid"></span>
      </div>
      <button class="ds-button ds-button--primary mr-4 mb-4 focusable focusable__element"
              type="submit" [disabled]="createTicketForm.invalid || isEmptyCategories">
        {{ "CREATE.TICKET.FORM.BUTTON.SUBMIT" | translate }}
      </button>
      <button class="ds-button ds-button--secondary mr-4 mb-4 focusable focusable__element"
              type="button"
              (click)="onCancelSubmitForm()">
        {{ "CREATE.TICKET.FORM.BUTTON.CANCEL" | translate }}
      </button>
    </div>
  </form>
</div>
