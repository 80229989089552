<a
  class="usp-nav-tile"
  href="{{navTile.url}}"
  target="_blank"
  rel="noopener noreferrer"
>
  <div class="usp-nav-tile-box">
    <img class="usp-nav-tile-box-icon" src="{{ navTile.icon }}" alt="Greeting">
    <p class="usp-nav-tile-box-title">{{ navTile.title | translate }}</p>
  </div>
</a>
