import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertDate'
})
export class ConvertDatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    const date = new Date(Number(value.slice(6, value.length - 2)));

    return date.toLocaleDateString('en-GB', {hour: '2-digit', minute: '2-digit', hour12: true, timeZoneName: 'short'}).toUpperCase();
  }

}
