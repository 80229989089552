<ng-container *ngIf="isDesktop">
  <div class="ticket-table desktop">
    <table mat-table [dataSource]="tickets">
      <ng-container matColumnDef="TicketID">
        <th mat-header-cell *matHeaderCellDef> Ticket ID </th>
        <td mat-cell *matCellDef="let ticket"> {{ticket.ID}} </td>
      </ng-container>

      <ng-container matColumnDef="Subject">
        <th mat-header-cell *matHeaderCellDef> Subject </th>
        <td mat-cell *matCellDef="let ticket" [innerHTML]="ticket.Name"></td>
      </ng-container>

      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef> Status </th>
        <td (mouseenter)="onTooltipHover($event)"
            *matCellDef="let ticket"
            mat-cell>
          <div class="status-cell">
            <span>{{ticket.ServiceRequestUserLifeCycleStatusCodeText}}</span>
            <em class="ds-icon ds-icon--info"
                [ngbTooltip]="tooltipText"
                placement="right-top"
                tooltipClass="tooltip-custom-class">
            </em>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="CreatedOn">
        <th mat-header-cell *matHeaderCellDef> Created on </th>
        <td mat-cell *matCellDef="let ticket"> {{ticket.CreationDateTime | convertDate }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columns"></tr>
      <tr mat-row *matRowDef="let rowData; columns: columns"
          (click)="openCertainTicket(rowData.ID)">
      </tr>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="isMobile || isTablet">
  <div class="ticket-table mobile">
    <div class="row" *ngFor="let ticket of tickets">

      <div class="sub-row" (click)="openCertainTicket(ticket.ID)">
        <div class="cell left">
          <p class="cell-header">Ticket ID</p>
          <p class="cell-text blue">{{ticket.ID}}</p>
        </div>
        <div class="cell right">
          <p class="cell-header">Subject</p>
          <p class="cell-text blue" [innerHTML]="ticket.Name"></p>
        </div>
      </div>

      <ds-accordion small="true" indented="true">
        <ds-accordion-item>
          <div class="sub-row">
            <ng-container>
              <div class="cell left">
                <p class="cell-header">Status</p>
                <div class="cell-text">
                  <span>{{ticket.ServiceRequestUserLifeCycleStatusCodeText}}</span>
                  <em class="ds-icon ds-icon--info"
                      [ngbTooltip]="tooltipText"
                      placement="right"
                      tooltipClass="tooltip-custom-class"
                      triggers="click:blur"
                      (click)="onTooltipHover($event)">
                  </em>
                </div>
              </div>
              <div class="cell right">
                <p class="cell-header">Created on</p>
                <p class="cell-text">{{ticket.CreationDateTime | convertDate }}</p>
              </div>
            </ng-container>
          </div>
        </ds-accordion-item>
      </ds-accordion>

    </div>
  </div>
</ng-container>
