import { Injectable } from '@angular/core';
import { APIConst } from '../shared/shared.enum';
import { CSRF_TOKEN } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor( ) { }

  generateCSRF(): void {
    const req = new XMLHttpRequest();
    req.open('GET', `${APIConst.api}${APIConst.ticketCategories}`, false);
    req.setRequestHeader(CSRF_TOKEN.HEADER_NAME, CSRF_TOKEN.FETCH);
    req.send(null);
    const token = req.getResponseHeader(CSRF_TOKEN.HEADER_NAME);
    document.cookie = CSRF_TOKEN.COOKIE_NAME + '=' + token;
  }

}
