import { Component, OnInit, SecurityContext } from '@angular/core';
import { TicketList, TicketStatus } from '../../shared/shared.interfaces';
import { TicketsService } from '../../services/tickets.service';
import { TranslateService } from '@ngx-translate/core';
import { TICKET_STATUS, TICKET_STATUS_GROUP } from '../../shared/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../shared/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'usp-all-tickets',
  templateUrl: './usp-all-tickets.component.html',
  styleUrls: ['./usp-all-tickets.component.scss']
})
export class UspAllTicketsComponent implements OnInit {
  ticketStatus = TICKET_STATUS.OPEN;
  tickets: Array<TicketList>;
  TICKET_STATUS_OPTIONS: Array<TicketStatus> = [
    {
      desc: this.sanitizer.sanitize(SecurityContext.HTML, TICKET_STATUS_GROUP.OPEN),
      id: TICKET_STATUS.OPEN,
      active: true
    },
    {
      desc: this.sanitizer.sanitize(SecurityContext.HTML, TICKET_STATUS_GROUP.COMPLETED),
      id: TICKET_STATUS.COMPLETED,
      active: false
    },
    {
      desc: this.sanitizer.sanitize(SecurityContext.HTML, TICKET_STATUS_GROUP.ALL),
      id: TICKET_STATUS.ALL,
      active: false
    }
  ];
  showErrorNotification = false;
  createNewTicketURL = environment.iESMSupportURL;

  constructor(
    private ticketService: TicketsService,
    public translateService: TranslateService,
    public sanitizer: DomSanitizer,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.authService.fetchUser();
    this.getOpenTickets();
  }

  get errorNotification(): string {
    return this.translateService.instant('ALL.TICKETS.ERROR.MESSAGE');
  }

  getOpenTickets(): void {
    this.showErrorNotification = false;
    this.ticketService.getOpenTickets().subscribe(
      respond => this.tickets = respond?.entities,
      error => {
        console.error(error);
        this.showErrorNotification = true;
      }
    );
  }

  getCompletedTickets(): void {
    this.showErrorNotification = false;
    this.ticketService.getCompletedTickets().subscribe(
      respond => this.tickets = respond?.entities,
      error => {
        console.error(error);
        this.showErrorNotification = true;
      }
    );
  }

  getAllTickets(): void {
    this.showErrorNotification = false;
    this.ticketService.getAllTickets().subscribe(
      respond => this.tickets = respond?.entities,
      error => {
        console.error(error);
        this.showErrorNotification = true;
      }
    );
  }

  getTickets(): void {
    if (this.ticketStatus === TICKET_STATUS.OPEN) {
      this.getOpenTickets();
    } else if (this.ticketStatus === TICKET_STATUS.COMPLETED) {
      this.getCompletedTickets();
    } else {
      this.getAllTickets();
    }
  }

  onChangeTicketStatus(options: string[]): void {
    this.ticketStatus = this.sanitizer.sanitize(SecurityContext.HTML, options[0]);
    this.getTickets();
  }

}
