<div class="usp-customer-info usp-main-content-wrapper">
  <usp-breadcrumbs></usp-breadcrumbs>
  <div class="usp-customer-info-main" *ngIf="isDesktop">
     <img class="usp-customer-info-main-icon"
           src="assets/icons/personMaleHeadset_R_neg_blue.png"
          alt="support">
     <div class="usp-customer-info-main-text">
       <p>{{ "BANNER.CUSTOMER.INFO.GREETING" | translate }}</p>
       <p>{{ "BANNER.CUSTOMER.INFO.PLEASE.NOTE" | translate }}</p>
       <ul>
         <li *ngFor="let text of sanitizedTextArray"
             [innerHtml]="text | sanitize: 'html'">
         </li>
       </ul>
       <p>{{ "BANNER.CUSTOMER.INFO.THANK.YOU" | translate }}</p>
       <span>{{ "BANNER.CUSTOMER.INFO.SIGNATURE" | translate }}</span>
    </div>
  </div>
  <usp-carousel *ngIf="isMobile || isTablet"></usp-carousel>
</div>



