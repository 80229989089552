import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { APIConst } from '../shared.enum';


@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient) {}

  fetchUser(): void {
    this.http.get(APIConst.userAttributes)
      .subscribe((): void => {
            // If no error occurred means, that user authenticated.
          },
          (error: any) => {
            console.error(error);

            // Workaround to trigger login logic.
            if (error.status === 200) {
              window.location.reload();
            }
        });
    }
}
