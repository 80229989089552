<div class="update-ticket usp-main-content-wrapper">

  <ds-message *ngIf="showErrorNotification"
              [message]="errorNotification"
              class="error-notification"
              type="error"
              [closable]="true">
  </ds-message>

  <h1 [innerHTML]=[ticketTitle]></h1>

  <table class="ticket-header-information-desktop" *ngIf="isDesktop">
    <tr class="first-row">
      <td class="name">Ticket ID</td>
      <td class="value">{{ticket.ID}}</td>
      <td class="name">Status</td>
      <td class="value">{{ticket.ServiceRequestUserLifeCycleStatusCodeText}}</td>
      <td class="name">Created on</td>
      <td class="value">{{ticket.CreationDateTime | convertDate }}</td>
    </tr>
    <tr>
      <td class="name">Service Category</td>
      <td class="value">{{ticket.ServiceTermsServiceIssueName}}</td>
      <td class="name">Incident Category</td>
      <td class="value">{{ticket.IncidentCategoryName}}</td>
      <td class="name">Changed on</td>
      <td class="value">{{ticket.LastChangeDateTime | convertDate }}</td>
    </tr>
  </table>

  <table class="ticket-header-information-mobile" *ngIf="isMobile || isTablet">
    <tr class="row">
      <td>Ticket ID</td>
      <td>{{ticket.ID}}</td>
    </tr>
    <tr class="row">
      <td>Service Category</td>
      <td>{{ticket.ServiceTermsServiceIssueName}}</td>
    </tr>
    <tr class="row">
      <td>Status</td>
      <td>{{ticket.ServiceRequestUserLifeCycleStatusCodeText}}</td>
    </tr>
    <tr class="row">
      <td>Incident Category</td>
      <td>{{ticket.IncidentCategoryName}}</td>
    </tr>
    <tr class="row">
      <td>Created on</td>
      <td>{{ticket.CreationDateTime | convertDate }}</td>
    </tr>
    <tr class="row">
      <td>Changed on</td>
      <td>{{ticket.LastChangeDateTime | convertDate }}</td>
    </tr>
  </table>

  <h2>Interactions</h2>
  <form class="form" [formGroup]="updateTicketForm" (ngSubmit)="onSubmitForm()">
    <div class="interactions">
      <div class="wrapper-input reply-input">
        <label for="reply" class="reply-label">Reply</label>
        <ds-textarea
          formControlName="reply"
          id="reply"
          [showErrorMessages]="showErrorMessages"
          label="Reply"
          placeholder="Reply"
        ></ds-textarea>
      </div>

      <div class="wrapper-input" *ngFor="let interaction of ticketInteractionsCollection">
        <label [innerHTML]="interaction.author"></label>
        <div class="interaction-text"
             [innerHTML]="interaction.text">
        </div>
      </div>
    </div>

    <usp-attachment [previousCustomerAttachments]="previousCustomerAttachments"
                    [agentAttachments]="agentAttachments"
                    [removeFiles]="removeFiles"
                    (sendNewAttachments)="getNewAttachments($event)">
    </usp-attachment>

    <div class="buttons">
      <button class="ds-button ds-button--primary mr-4 mb-4 focusable focusable__element"
              type="submit"
              [disabled]="updateTicketForm.invalid || isTicketStatusClosed">
        {{ "CREATE.TICKET.FORM.BUTTON.SUBMIT" | translate }}
      </button>

      <button class="ds-button ds-button--secondary mr-4 mb-4 focusable focusable__element"
              type="button"
              (click)="onCloseTicket()"
              *ngIf="isTicketStatusActive">
        Request to close
      </button>

      <button class="ds-button ds-button--secondary mr-4 mb-4 focusable focusable__element"
              type="button"
              (click)="onCancelSubmitForm()">
        {{ "CREATE.TICKET.FORM.BUTTON.CANCEL" | translate }}
      </button>
    </div>

  </form>
</div>
