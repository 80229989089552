import { IEnvironment } from 'src/app/shared/shared.interfaces';

export const environment: IEnvironment = {
  dev: false,
  test: false,
  production: true,
  defaultLocale: 'en',
  lxpWebComponentsLink: '/web-components.js',
  certificationHubURL: 'http://accounts.sap.com/saml2/idp/sso?sp=sapcertification.questionmark.eu&RelayState=/602056/ext/CertificationHub/candidate-dashboard',
  helpCenterURL: 'https://learninghub.sap.com/help-center/faq/',
  iESMSupportURL: 'https://lso-ssrv-app.cfapps.eu10-004.hana.ondemand.com'
};
