<div class=" usp-main-content-wrapper background-gradient">
  <div class="usp-all-tickets">
    <ds-message *ngIf="showErrorNotification"
                [message]="errorNotification"
                type="error"
                [closable]="true">
    </ds-message>

    <h1>{{ "ALL.TICKETS.TITLE" | translate }}</h1>

    <div class="wrapper-button-dropdown">
      <ds-combo-box
          class="ticket-status-dropdown"
          (valueChange)="onChangeTicketStatus($event)">
          <ds-combo-box-dropdown [showSearchBar]="false">
            <ds-combo-box-dropdown-group class="dropdown-group" >
              <ds-combo-box-dropdown-option *ngFor="let option of TICKET_STATUS_OPTIONS"
                                            [isActive]="option.active"
                                            [label]="option.desc | translate"
                                            [value]="option.id">
                <div>
                  <span [innerHTML]="option.desc | translate"></span>
                </div>
              </ds-combo-box-dropdown-option>
            </ds-combo-box-dropdown-group>
          </ds-combo-box-dropdown>
      </ds-combo-box>
      <div class="reload-icon" (click)="getTickets()"></div>
    </div>

    <usp-table-all-tickets [tickets]="tickets"></usp-table-all-tickets>

    <a
      href="{{createNewTicketURL}}"
      target="_blank"
      rel="noopener noreferrer"
    >
      <button class="ds-button ds-button--primary mr-4 mb-4 focusable focusable__element">
        {{ "ALL.TICKETS.BUTTON.CREATE.NEW.TICKET" | translate }}
      </button>
    </a>
  </div>
</div>
