<div class="usp-static-page">
  <div class="usp-static-page-content">
    <div class="entry-message usp-main-content-wrapper">
      <p>Sorry, <span>this page cannot be found.</span></p>
    </div>
    <div class="main-message usp-main-content-wrapper ">
      <p>Dear Learner,</p>
      <p>The page you are looking for does not exist or has been moved.</p>
      <p class="contact-information">Please try again by returning
        <a href="https://learninghub.sap.com/">Home</a> or
        <a href="https://support.learning.sap.com/">contact our technical support team.</a>
      </p>
    </div>
  </div>
</div>

