import { NgModule } from '@angular/core';
import {
  CommonModule,
  HashLocationStrategy,
  APP_BASE_HREF,
  LocationStrategy,
} from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from 'src/app/components/not-found/not-found.component';

import { RoutesPathBreadcrumbs, RoutesPathConfig } from './shared/shared.enum';
import { UspHomeComponent } from './components/usp-home/usp-home.component';
import { UspCreateNewTicketComponent } from './components/usp-create-new-ticket/usp-create-new-ticket.component';
import { UspAllTicketsComponent } from './components/usp-all-tickets/usp-all-tickets.component';
import { UspTicketComponent } from './components/usp-update-ticket/usp-ticket.component';
import { UspAllTicketsNavigationComponent } from './shared/components/usp-all-tickets-navigation/usp-all-tickets-navigation.component';


export const routes: Routes = [
  {
    path: RoutesPathConfig.empty,
    component: UspHomeComponent,
    data: { breadcrumb: RoutesPathBreadcrumbs.home },
  },
  {
    path: RoutesPathConfig.login,
    redirectTo: RoutesPathConfig.empty,
  },
  {
    path: RoutesPathConfig.createNewTicket,
    component: UspCreateNewTicketComponent,
    data: { breadcrumb: RoutesPathBreadcrumbs.createNewTicket },
  },
  {
    path: RoutesPathConfig.allTickets,
    component: UspAllTicketsNavigationComponent,
    data: { breadcrumb: RoutesPathBreadcrumbs.allTickets },
    children: [
      {
        path: '',
        component: UspAllTicketsComponent,
      },
      {
        path: RoutesPathConfig.ticketID,
        component: UspTicketComponent,
        data: { breadcrumb: RoutesPathBreadcrumbs.ticketID },
      }
    ]
  },
  {
    path: RoutesPathConfig.notFound,
    component: NotFoundComponent,
  },
  {
    path: RoutesPathConfig.empty,
    redirectTo: RoutesPathConfig.home,
    pathMatch: 'full',
  },
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
}),
    CommonModule
  ],
  exports: [RouterModule],
  providers: [
    { provide: APP_BASE_HREF, useValue: '' },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
  ],
})
export class AppRoutingModule { }
