import { Component, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { SharedService } from 'src/app/shared/services/shared.service';
import { ScriptLoaderService } from './shared/services/script-loader.service';
import { environment } from 'src/environments/environment';
import { DeviceDetectorService } from './shared/services/device-detector.service';


@Component({
  selector: 'app-usp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ScriptLoaderService],
})
export class AppComponent implements OnInit {
  public storedLocale: string;

  constructor(
    private sharedService: SharedService,
    private translate: TranslateService,
    private scriptService: ScriptLoaderService,
    private deviceService: DeviceDetectorService,
    private renderer: Renderer2,
  ) {
    this.deviceService.detectDevice();
    this.storedLocale = this.sharedService.getLocale().toLocaleLowerCase();

    this.translate.setDefaultLang(environment?.defaultLocale);
    this.translate.use(this.storedLocale);

    this.renderer.listen('window', 'resize', (e: UIEvent) => {
      const w = e.target as Window;
      this.deviceService.setWindowWidth(w.innerWidth);
    });
  }

  ngOnInit(): void {
    this.scriptService
      .load('lxpWebComponents')
      .then((data) => {
        console.log('LXP Web-components were successfully loaded.');
      })
      .catch((error) => console.log(error));
     }
}

