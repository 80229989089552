import { Component, EventEmitter, OnInit, SecurityContext } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TicketsService } from '../../services/tickets.service';
import {
  AttachmentCollection,
  PopUpContent,
  RequestText,
  TextCollection,
  TicketAttachment,
  TicketByID,
  TicketReply,
  UpdatedTicket
} from '../../shared/shared.interfaces';
import { TokenService } from '../../services/token.service';
import { DeviceDetectorService } from '../../shared/services/device-detector.service';
import { INTERACTIONS, TICKET_STATUS, ERROR_STATUS, ATTACHMENT_AUTHOR, CLOSE_TICKET_DIALOG } from '../../shared/constants';
import { DomSanitizer } from '@angular/platform-browser';
import { UspPopupComponent } from '../../shared/components/usp-popup/usp-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'usp-ticket',
  templateUrl: './usp-ticket.component.html',
  styleUrls: ['./usp-ticket.component.scss']
})
export class UspTicketComponent implements OnInit {

  id: number | undefined;
  subscription: Subscription;
  isMobile: boolean = this.deviceDetectorService.isMobile;
  isTablet: boolean = this.deviceDetectorService.isTablet;
  isDesktop: boolean = this.deviceDetectorService.isDesktop;
  showErrorNotification = false;
  errorNotification: string;
  updateTicketForm: FormGroup;
  showErrorMessages = false;
  ticket: TicketByID = {
    ID: 0,
    Name: '',
    CreationDateTime: '',
    LastChangeDateTime: '',
    ServiceRequestUserLifeCycleStatusCodeText: '',
    ServiceTermsServiceIssueName: '',
    IncidentCategoryName: '',
    ServiceRequestTextCollection: [],
    ServiceRequestAttachmentFolder: [],
  };
  ticketInteractionsCollection: RequestText[] = [];
  ticketReply: Array<TicketReply> = [];
  previousCustomerAttachments: AttachmentCollection[] = [];
  agentAttachments: AttachmentCollection[] = [];
  newAttachments: Array<TicketAttachment> = [];
  removeFiles: EventEmitter<boolean> = new EventEmitter();
  isTicketStatusClosed = false;
  isTicketStatusActive = true;
  ticketTitle = '';

  constructor(
    private activateRoute: ActivatedRoute,
    public translateService: TranslateService,
    public router: Router,
    private ticketService: TicketsService,
    public tokenService: TokenService,
    private deviceDetectorService: DeviceDetectorService,
    public sanitizer: DomSanitizer,
    public popUpAfterClose: MatDialog,
    private authService: AuthService,
  ){
    this.subscription = activateRoute.params.subscribe(params => this.id = params.id);

    this.updateTicketForm = new FormGroup({
      reply: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.authService.fetchUser();
    this.getTicketByID(this.id);
    this.tokenService.generateCSRF();
  }

  getTicketByID(id: number): void {
    this.ticketService.getTicketByID(id).subscribe(
      respond => {
        this.ticket = respond;
        const ticketSubject = respond.Name.replace(/&amp;#10;|&#10;/g, '<br>')
          .replace(/amp;/g, '');
        this.ticketTitle = `Title ${respond.ID}: ${ticketSubject}`;
        this.convertRequestTextCollection(respond.ServiceRequestTextCollection);
        this.convertRequestAttachmentCollection(respond.ServiceRequestAttachmentFolder);
        this.checkTicketStatus(respond.ServiceRequestUserLifeCycleStatusCodeText.toLowerCase());
      },
      error => this._completeSubmitError(error, 'TICKET.ID.ERROR.MESSAGE.BACKEND')
    );
  }

  checkTicketStatus(status): void {
    if (status === TICKET_STATUS.CLOSED) {
      this.isTicketStatusClosed = true;
      this.isTicketStatusActive = false;
    }

    if (status === TICKET_STATUS.COMPLETED) {
      this.isTicketStatusActive = false;
    }

  }

  convertRequestTextCollection(textCollection: TextCollection[]): void{
    this.ticketInteractionsCollection = [];
    textCollection.forEach((i) => {

      if (i.TypeCode === INTERACTIONS.AUTHOR_AGENT_TYPE_CODE ||
        i.TypeCode === INTERACTIONS.AUTHOR_CUSTOMER_TYPE_CODE_1 ||
        i.TypeCode === INTERACTIONS.AUTHOR_CUSTOMER_TYPE_CODE_2) {
        const date =  new Date(Number(i.CreatedOn.slice(6, i.CreatedOn.length - 2)));
        const text = i.Text.replace(/&amp;#10;|&#10;/g, '<br>')
          .replace(/amp;/g, '');
        let author;

        switch (i.TypeCode) {
          case INTERACTIONS.AUTHOR_AGENT_TYPE_CODE:

            author = i.CreatedBy ? i.CreatedBy.split(' ')[0] + INTERACTIONS.AUTHOR_AGENT_TEXT_WITH_NAME : INTERACTIONS.AUTHOR_AGENT_TEXT;

            break;
          case INTERACTIONS.AUTHOR_CUSTOMER_TYPE_CODE_1:
          case INTERACTIONS.AUTHOR_CUSTOMER_TYPE_CODE_2:

            author = i.CreatedBy ? i.CreatedBy.split(' ')[0] : INTERACTIONS.AUTHOR_CUSTOMER_TEXT;

            break;
        }

        const interaction = {
          author,
          text,
          date
        };
        this.ticketInteractionsCollection.push(interaction);
      }

    });
    this.ticketInteractionsCollection.sort((a, b) => a.date > b.date ? 1 : -1);
}

  convertRequestAttachmentCollection(attachmentCollection: AttachmentCollection[]): void{
    this.previousCustomerAttachments = [];
    this.agentAttachments = [];
    attachmentCollection.forEach((i) => {
      const attachment = {
        ObjectID: i.ObjectID,
        Name: i.Name,
        Binary: i.Binary
      };

      if (i.CreatedBy === ATTACHMENT_AUTHOR.CUSTOMER) {
        this.previousCustomerAttachments.push(attachment);
      } else {
        this.agentAttachments.push(attachment);
      }

    });
  }

  getNewAttachments(newAttachments: TicketAttachment[]): void{
    this.newAttachments = newAttachments;
  }

  _completeSubmitSuccess(message: string): void {
    localStorage.setItem('successNotification', message);
    this.removeFiles.emit(true);
    this.router.navigateByUrl('/');
  }

  _completeSubmitError(error: any, message: string): void {
    window.scrollTo(0, 0);
    console.log(error);
    this.errorNotification = this.translateService.instant(message);
    this.showErrorNotification = true;
    this.removeFiles.emit(true);
  }

  onSubmitForm(): void {
    const reply = this.updateTicketForm.controls.reply.value;
    const sanitizedValue = this.sanitizer.sanitize(SecurityContext.HTML, reply)
      .replace(/&#10;|&amp;#10;/g, '<br>').replace(/amp;/g, '');

    if (reply){
      this.ticketReply =
        [
          {
            Text: sanitizedValue
          }
        ]
        ;
    }

    const updatedTicket: UpdatedTicket = {
      id: this.ticket.ID,
      ServiceRequestAttachmentFolder: this.newAttachments,
      ServiceRequestDescription: this.ticketReply,
    };

    if (this.ticketReply.length || this.newAttachments.length) {
      this.ticketService.updateTicket(updatedTicket).subscribe(
          () => {
            this.updateTicketForm.reset();
            const message = `The ticket <b>#${this.id}</b> was successfully updated.`;
            this._completeSubmitSuccess(message);
          },
          error => {
            let message: string;

            if (error.status === ERROR_STATUS.SUPPORT_TEAM_REPLY_IN_PROCESS) {
              message = 'TICKET.ID.ERROR.MESSAGE.SUPPORT.TEAM.REPLY.IN.PROCESS';
            } else {
              message = 'TICKET.ID.ERROR.MESSAGE.BACKEND';
            }

            this._completeSubmitError(error, message);
          }
      );
    }

  }

  onCloseTicket(): void {
    const ticketID = {
      id: this.ticket.ID
    };
    const data: PopUpContent = {
      title: CLOSE_TICKET_DIALOG.TITLE,
      text: CLOSE_TICKET_DIALOG.TEXT,
      confirmButton: CLOSE_TICKET_DIALOG.CONFIRM_BUTTON,
      cancelButton: CLOSE_TICKET_DIALOG.CANCEL_BUTTON,
    };
    this.popUpAfterClose.open(UspPopupComponent, {
      panelClass: 'custom-dialog-container',
      height: '275px',
      backdropClass: 'custom-backdrop',
      data
    }).afterClosed().subscribe(
      result => {

        if (result) {
          this.sendRequestToCloseTicket(ticketID);
        }

      }
    );
  }

  sendRequestToCloseTicket(ticketID): void {
    this.ticketService.closeTicket(ticketID).subscribe(
         () => {
           const message = 'Your request to close this ticket has been submitted successfully. Our agents will update the status.';
           this._completeSubmitSuccess(message);
        },
         error => {
           let message: string;

           if (error.status === ERROR_STATUS.SUPPORT_TEAM_REPLY_IN_PROCESS) {
             message = 'TICKET.ID.ERROR.MESSAGE.SUPPORT.TEAM.REPLY.IN.PROCESS';
           } else {
             message = 'TICKET.ID.ERROR.MESSAGE.BACKEND';
          }

           this._completeSubmitError(error, message);
        }
    );
  }

  onCancelSubmitForm(): void {
    this.updateTicketForm.reset();
    this.removeFiles.emit(true);
    this.router.navigateByUrl('/');
  }

}
