import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'usp-all-tickets-navigation',
  templateUrl: './usp-all-tickets-navigation.component.html',
  styleUrls: ['./usp-all-tickets-navigation.component.scss']
})
export class UspAllTicketsNavigationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
