<section
  class="usp-tabs-wrapper">
  <mat-tab-group
    class="usp-tabs-menu"
    (selectedTabChange)="selectTab($event)"
    [(selectedIndex)]="tabIndex">
    <mat-tab
      *ngFor="let tab of faqsInfo"
      [label]="tab?.category">
      <ng-template mat-tab-label>
        <span [class.active-tab]="tabIndex===tab?.index">
		    {{tab?.category | translate}}
		  </span>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</section>

