import { Component, OnInit } from '@angular/core';
import { FaqsService } from '../../../services/faqs.service';
import { FAQItem, FAQsInfo, NavTile } from '../../shared.interfaces';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { Subscription } from 'rxjs';
import { SharedService } from '../../services/shared.service';
import { environment } from 'src/environments/environment';
import '@udex/web-components/dist/Tab.js';
import '@udex/web-components/dist/TabNavigation.js';

@Component({
  selector: 'usp-faqs',
  templateUrl: './usp-faqs.component.html',
  styleUrls: ['./usp-faqs.component.scss']
})
export class UspFaqsComponent implements OnInit {
  tabIndex = 1;
  FAQS_INFO: Array<FAQsInfo> = [];
  titles: any = {};
  showErrorNotification = false;
  defaultLanguage = 'EN';
  currentLanguage: string;


  get navTile(): NavTile {
    return {
      icon: '../../assets/icons/headset_blue.png',
      title: this.translateService.instant('HOME.NAV.TILES.MANAGE.TITLE'),
      button: this.translateService.instant('HOME.NAV.TILES.MANAGE.BUTTON'),
      url: environment?.iESMSupportURL,
    };
  }

  get errorNotification(): string {
    return this.translateService.instant('HOME.ERROR.MESSAGE');
  }

  constructor(
    public translateService: TranslateService,
    private faqsService: FaqsService,
    private languageService: LanguageService,
    private sharedService: SharedService,
  ) {
    this.currentLanguage = this.sharedService.getLocale().toUpperCase();
  }

  ngOnInit(): void {
    this.getFAQsInfo(this.currentLanguage);
  }

  groupFAQItems(items: Array<FAQItem>, language: string): Array<FAQsInfo> {
    return items.reduce((acc, item) => {
      const index = acc.findIndex((group) => group.category === item.subtopic);
      let FaqData;

      if (language === this.defaultLanguage && item.title) {
        FaqData = { title: item.title, url: item.url };
      } else if (item.titleTranslation) {
        FaqData = { title: item.titleTranslation, url: item.url };
      }

      if (index !== -1 && FaqData) {
        acc[index].value.push(FaqData);
      } else if (FaqData) {
        acc.push({ category: item.subtopic, value: [FaqData] });
      }

      return acc;
    }, []);
  }

  getFAQsInfo(language: string): Subscription {
    this.showErrorNotification = false;

    if (this.faqsService.useMockData) {
      language = 'EN';
    }

    return this.faqsService.getFAQs(language).subscribe(
      res => {
        this.FAQS_INFO = this.groupFAQItems(res.value, language);
        this.updateFaqsInfo({ detail: { currentPage: this.tabIndex }});
      },
      error => {
        console.error(error);
        this.showErrorNotification = true;
      }
    );
  }
  updateFaqsInfo(tabIndex): void {
    if (!tabIndex?.detail) {
      return;
    }

    this.tabIndex = tabIndex?.detail?.currentPage - 1 ?? 0;
    this.titles = this.FAQS_INFO[this.tabIndex];
  }
}
