import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpXsrfTokenExtractor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CSRF_TOKEN } from '../shared/constants';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const cookieHeaderName = CSRF_TOKEN.HEADER_NAME;
    const csrfToken = this.tokenExtractor.getToken() as string;

    if (csrfToken !== null && !req.headers.has(cookieHeaderName)) {
      req = req.clone({ headers: req.headers.set(cookieHeaderName, csrfToken) });
    }

    return next.handle(req);
  }
}
