<div class="usp-questions-table">
  <table mat-table [dataSource]="faqsInfo.value">
    <ng-container matColumnDef="Links">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let faq">
        {{faq.title}}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columns"></tr>
    <tr mat-row *matRowDef="let faq; columns: columns"
        (click)="openFaqUrl(faq.url)">
    </tr>
  </table>
</div>
