import { AfterViewInit, ChangeDetectorRef, Component, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { DomSanitizer } from '@angular/platform-browser';
import { CarouselText } from '../../shared.interfaces';

@Component({
  selector: 'usp-carousel',
  templateUrl: './usp-carousel.component.html',
  styleUrls: ['./usp-carousel.component.scss']
})
export class UspCarouselComponent implements OnInit, AfterViewInit {

  @ViewChild('myCarousel') myCarousel: NguCarousel<any>;
  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    load: 3,
    interval: {timing: 4000, initialDelay: 1000},
    loop: true,
    touch: true,
    velocity: 0.2
  };
  carouselText: Array<CarouselText> = [
    { text: [
        'Dear valued learner,',
        'please note the following Information while contacting us:',
        'You can expect to receive a response from us within one business day.',
        'Our service is available from Monday to Friday and in English only (submitting your query in a language other than English may impact the response time).',
      ]
    },
    { text: [
        'Please provide us with as much information as possible when entering your ticket e.g. your user ID (if applicable), screenshots of the issue, course code (if there is an issue with specific content), any reference numbers included in the error message / emails you have received etc., browser version, exact path to error / steps to reproduce in order for us to be able to reproduce the issue.',
        'Thank you',
        'Your SAP Learning Support Team'
      ]
    }
  ];
  sanitizedTextList = [];

  constructor(
    private cdr: ChangeDetectorRef,
    public sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.sanitizeInput();
  }

  sanitizeInput(): void {
    this.carouselText.forEach(
      i => {
        const sanitizedTextArray = [];
        i.text.forEach(
          text => {
            const sanitizedText = this.sanitizer.sanitize(SecurityContext.HTML, text);
            sanitizedTextArray.push(sanitizedText);
          }
        );
        this.sanitizedTextList.push(sanitizedTextArray);
      }
    );
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

}
