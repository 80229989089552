import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FAQsInfo } from '../../shared.interfaces';

@Component({
  selector: 'usp-tabs',
  templateUrl: './usp-tabs.component.html',
  styleUrls: ['./usp-tabs.component.scss']
})
export class UspTabsComponent implements OnInit {
  @Input() tabIndex = 0;
  @Input() faqsInfo: Array<FAQsInfo> = [];
  @Output() tabChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {}

  selectTab(tabSource): void {
    const tabIndex = +tabSource.index;
    this.tabChanged.emit(tabIndex);
  }

}
