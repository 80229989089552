import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BusyIndicatorService } from './usp-busy-indicator.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class BusyIndicatorInterceptor implements HttpInterceptor {

  requestCount = 0;

  constructor(
    private busyIndicatorService: BusyIndicatorService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.requestCount++;
    this.busyIndicatorService.isLoading.next(true);

    return next.handle(request).pipe(
      finalize(
        () => {
          this.requestCount--;

          if (this.requestCount === 0) {
            this.busyIndicatorService.isLoading.next(false);
          }

        }
      )
    );
  }
}
