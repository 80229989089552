import { Component, Input, OnInit } from '@angular/core';
import { NavTile } from '../../shared.interfaces';

@Component({
  selector: 'usp-nav-tiles',
  templateUrl: './usp-nav-tiles.component.html',
  styleUrls: ['./usp-nav-tiles.component.scss']
})
export class UspNavTilesComponent implements OnInit {

   @Input() navTile: NavTile;

  constructor() { }

  ngOnInit(): void {
  }

}
