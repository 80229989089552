export enum RoutesPathConfig {
  'empty' = '',
  'home' = 'home',
  'createNewTicket' = 'create-new-ticket',
  'allTickets' = 'all-tickets',
  'table' = 'table',
  'ticketID' = 'ticket/:id',
  'notFound' = 'not-found',
  'ticket' = 'ticket',
  'login' = 'login',
}

export enum RoutesPathBreadcrumbs {
  'empty' = '',
  'home' = 'SAP User Support Center',
  'createNewTicket' = 'Create new ticket',
  'allTickets' = 'All tickets',
  'ticketID' = 'Ticket ',
  'notFound' = 'not-found',
}

export enum APIConst {
  'api' = '/api',
  'user' = '/user',
  'editions' = '/editions',
  'userAttributes' = '/user-api/attributes',
  'openTickets' = '/GetOpenTickets()',
  'allTickets' = '/GetAllUserTickets()',
  'completedTickets' = '/GetCompletedTickets()',
  'createTicket' = '/CreateTicket',
  'updateTicket' = '/UpdateTicket',
  'ticketById' = '/GetTicketById',
  'ticketAttachments' = '/GetTicketAttachments',
  'ticketInteractions' = '/GetTicketInteractions',
  'ticketCategories' = '/GetCategories()',
  'closeTicket' = '/CloseTicket',
  'cms' = '/cms',
  'allFaq' = '/getFaqs',
}

export enum InternalUsers {
  c = 'c',
  d = 'd',
  i = 'i'
}

export enum Environment {
  Local = 'local',
  Dev = 'dev',
  Test = 'test',
  Prod = 'prod'
}
