<div class="usp-pop-up">
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content class="mat-typography">
    <p>{{data.text}}</p>
  </mat-dialog-content>
  <mat-dialog-actions align="start">
    <button class="ds-button ds-button--primary mr-4 mb-4 focusable focusable__element"
            type="button"
            [mat-dialog-close]="true"
            (click)="onConfirmDialog()">
      {{data.confirmButton}}
    </button>
    <button class="ds-button ds-button--secondary mr-4 mb-4 focusable focusable__element"
            type="button"
            mat-dialog-close>
      {{data.cancelButton}}
    </button>
  </mat-dialog-actions>
</div>
