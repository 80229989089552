import {environment as local} from './environment.local';
import {environment as dev} from './environment.dev';
import {environment as test} from './environment.test';
import {environment as prod} from './environment.prod';
import {IEnvironment} from 'src/app/shared/shared.interfaces';
import {Environment} from 'src/app/shared/shared.enum';
import {ACTIVE_ENVIRONMENT} from '../global';

const ENVIRONMENT_MAP = {
  [Environment.Local]: local,
  [Environment.Dev]: dev,
  [Environment.Test]: test,
  [Environment.Prod]: prod,
};

export const environment: IEnvironment = ENVIRONMENT_MAP[ACTIVE_ENVIRONMENT] || dev;
