import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  constructor(private cookieService: CookieService) {}

  getLocale(): string {

    return this.cookieService.get('userLang') || environment?.defaultLocale;
  }

  setLocale(locale): void {
    this.cookieService.set('userLang', locale || environment?.defaultLocale, undefined, '/');
  }
}
