import { Component, Input, OnInit } from '@angular/core';
import { AdditionalInfo } from '../../shared.interfaces';


@Component({
  selector: 'usp-additional-info',
  templateUrl: './usp-additional-info.component.html',
  styleUrls: ['./usp-additional-info.component.scss']
})
export class UspAdditionalInfoComponent implements OnInit {

  @Input() info: AdditionalInfo;

  constructor() { }

  ngOnInit(): void {
  }

}
