import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from 'src/environments/environment';

import { AppRoutingModule } from 'src/app/app-routing.module';

import {
    ComboBoxDropdownModule,
    ComboBoxModule,
    InputModule,
    TextareaModule,
    UploadModule,
    BreadcrumbModule,
    NgDesignSystemModule,
    NavModule
} from 'ng-design-system';

import { AppComponent } from 'src/app/app.component';
import { BusyIndicatorComponent } from './components/usp-busy-indicator/usp-busy-indicator.component';
import { EmptyStateComponent } from './shared/components/usp-empty-state/usp-empty-state.component';
import { CookieService } from 'ngx-cookie-service';
import { UspCreateNewTicketComponent } from './components/usp-create-new-ticket/usp-create-new-ticket.component';
import { UspTicketComponent } from './components/usp-update-ticket/usp-ticket.component';
import { UspAllTicketsComponent } from './components/usp-all-tickets/usp-all-tickets.component';
import { UspHomeComponent } from './components/usp-home/usp-home.component';
import { UspNavTilesComponent } from './shared/components/usp-nav-tiles/usp-nav-tiles.component';
import { UspAdditionalInfoComponent } from './shared/components/usp-additional-info/usp-additional-info.component';
import { UspCustomerInfoComponent } from './shared/components/usp-customer-info/usp-customer-info.component';
import { UspAttachmentComponent } from './shared/components/usp-attachment/usp-attachment.component';
import { DndDirective } from './shared/components/usp-attachment/dnd.directive';
import { UspAttachedFileComponent } from './shared/components/usp-attached-file/usp-attached-file.component';
import { UspBreadcrumbsComponent } from './shared/components/usp-breadcrumbs/usp-breadcrumbs.component';
import { HeaderInterceptor } from './services/header.interceptor';
import { BusyIndicatorInterceptor } from './components/usp-busy-indicator/busy-indicator.interceptor';
import { UspCarouselComponent } from './shared/components/usp-carousel/usp-carousel.component';
import { NguCarouselModule } from '@ngu/carousel';
import { TokenInterceptor } from './services/token.interceptor';
import { CSRF_TOKEN } from './shared/constants';
import { ODataModule } from 'angular-odata';
import { UspTableAllTicketsComponent } from './shared/components/usp-table-all-tickets/usp-table-all-tickets.component';
import { MatTableModule } from '@angular/material/table';
import { ConvertDatePipe } from './pipes/convert-date.pipe';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { UspAllTicketsNavigationComponent } from './shared/components/usp-all-tickets-navigation/usp-all-tickets-navigation.component';
import { TextareaAutoresizeDirective } from './components/usp-create-new-ticket/textarea-autoresize.directive';
import { UspPopupComponent } from './shared/components/usp-popup/usp-popup.component';
import { MatDialogModule } from '@angular/material/dialog';
import { UspFaqsComponent } from './shared/components/usp-faqs/usp-faqs.component';
import { MatTabsModule } from '@angular/material/tabs';
import { UspTabsComponent } from './shared/components/usp-tabs/usp-tabs.component';
import { UspQuestionsComponent } from './shared/components/usp-questions/usp-questions.component';


export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    BusyIndicatorComponent,
    EmptyStateComponent,
    UspCreateNewTicketComponent,
    UspTicketComponent,
    UspAllTicketsComponent,
    UspHomeComponent,
    UspNavTilesComponent,
    UspAdditionalInfoComponent,
    UspCustomerInfoComponent,
    UspAttachmentComponent,
    DndDirective,
    UspAttachedFileComponent,
    UspBreadcrumbsComponent,
    UspCarouselComponent,
    UspTableAllTicketsComponent,
    ConvertDatePipe,
    SanitizePipe,
    UspAllTicketsNavigationComponent,
    TextareaAutoresizeDirective,
    UspPopupComponent,
    UspFaqsComponent,
    UspTabsComponent,
    UspQuestionsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    InputModule,
    AppRoutingModule,
    HttpClientModule,
    HttpClientXsrfModule,
    TranslateModule.forRoot({
      defaultLanguage: environment?.defaultLocale,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TextareaModule,
    UploadModule,
    ComboBoxModule,
    ComboBoxDropdownModule,
    BreadcrumbModule,
    NgDesignSystemModule,
    NguCarouselModule,
    HttpClientXsrfModule.withOptions({
      cookieName: CSRF_TOKEN.COOKIE_NAME,
      headerName: CSRF_TOKEN.HEADER_NAME
    }),
    MatTableModule,
    NgbTooltipModule,
    MatDialogModule,
    NavModule,
    MatTabsModule,
    ODataModule.forRoot({ serviceRootUrl: '/' })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    CookieService,
    { provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BusyIndicatorInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
