export const ENTRY_POINT = {
  HEADER: 'support',
  BANNER: 'support',
  ANNOUNCEMENT: 'support',
};

export const FORM_VALIDATION = {
  VEM_ITEM_ID_LENGTH: 40,
  FILE_SIZE: 31457280,
  FORBIDDEN_FILES_TYPES: ['ade', 'adp', 'app', 'asp', 'bas', 'bat', 'bhx', 'cab', 'ceo', 'chm', 'cmd', 'com', 'cpl',
    'crt', 'csr', 'der', 'exe', 'fxp', 'hlp', 'hta', 'inf', 'ins',
    'isp', 'its', 'js', 'jse', 'lnk', 'mad', 'maf', 'mag', 'mam', 'mar', 'mas', 'mat', 'mde', 'mim', 'msc', 'msi', 'msp', 'mst', 'ole', 'pcd', 'pif', 'reg',
    'scr', 'sct', 'shb', 'shs', 'vb', 'vbe', 'vbmacros', 'vbs', 'vsw', 'wmd', 'wmz', 'ws', 'wsc', 'wsf', 'wsh', 'xxe',
    'docm', 'xlsm', 'arj', 'cab', 'jar', 'lha', 'rar', 'tar', 'zip']
};

export const CSRF_TOKEN = {
  COOKIE_NAME: 'x-csrf-token',
  HEADER_NAME: 'x-csrf-token',
  FETCH: 'fetch'
};

export const  INTERACTIONS = {
  AUTHOR_CUSTOMER_TEXT: 'Customer',
  AUTHOR_CUSTOMER_TYPE_CODE_1: '10004',
  AUTHOR_CUSTOMER_TYPE_CODE_2: '10008',
  AUTHOR_AGENT_TEXT: 'Agent',
  AUTHOR_AGENT_TEXT_WITH_NAME: '<br>SAP support agent',
  AUTHOR_AGENT_TYPE_CODE: '10007',
};

export const TICKET_STATUS = {
  CLOSED: 'closed',
  OPEN: 'open',
  COMPLETED: 'completed',
  ALL: 'all'
};

export const TICKET_STATUS_GROUP = {
  OPEN: 'Open Tickets',
  COMPLETED: 'Completed Tickets',
  ALL: 'All Tickets'
};

export const ERROR_STATUS = {
  SUPPORT_TEAM_REPLY_IN_PROCESS: 412,
  HTTP_ERROR_RESPONSE: 401
};

export const ATTACHMENT_AUTHOR = {
  CUSTOMER: 'Technical User _SUPPORTPORT'
};

export const SERVICE_CATEGORY_ID = {
  VIRTUAL_EVENT_SERVICES: 'VE'
};

export const CLOSE_TICKET_DIALOG = {
  TITLE: 'Request to close',
  TEXT: 'You have chosen to close this ticket. Please note that this will indicate that your issue / request has been resolved and no further updates will be possible on the ticket. Please press Cancel to return to previous screen.',
  CONFIRM_BUTTON: 'Close Ticket',
  CANCEL_BUTTON: 'Cancel'
};

export const CREATE_TICKET_DIALOG = {
  TITLE: 'Ticket created',
  TEXT: 'Your ticket has been successfully created. An agent will respond to you shortly.',
  CONFIRM_BUTTON: 'Back to Support Center',
  CANCEL_BUTTON: 'Create new ticket'
};

export const REQUEST_QUERY = {
  LANGUAGE: 'languageIsoCode',
  ID: 'id',
};
