import { Component, OnInit } from '@angular/core';
import { AdditionalInfo } from '../../shared/shared.interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'usp-home',
  templateUrl: './usp-home.component.html',
  styleUrls: ['./usp-home.component.scss']
})
export class UspHomeComponent implements OnInit {

  showSuccessNotification = false;
  successNotification: string;

  get additionalInfo(): AdditionalInfo {
    return {
      text: this.translateService.instant('HOME.ADDITIONAL.INFO.TEXT'),
      };
}

  constructor(
    public translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.checkSuccessNotification();
  }

  checkSuccessNotification(): void {
    this.showSuccessNotification = Boolean(localStorage.getItem('successNotification'));
    this.successNotification = localStorage.getItem('successNotification');
  }

  onCloseNotification(): void {
    localStorage.removeItem('successNotification');
  }

}
