import { Injectable } from '@angular/core';
import { DeviceDetectorService as DeviceService } from 'ngx-device-detector';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

interface IDeviceInfo {
  browser: string;
  browser_version: string;
  device: string;
  deviceType: string;
  orientation: string;
  os: string;
  os_version: string;
  userAgent: string;
}

@Injectable({ providedIn: 'root' })
export class DeviceDetectorService {
  private _isMobile: boolean;
  private _isTablet: boolean;
  private _isDesktop: boolean;
  private _deviceInfo: IDeviceInfo;
  private minDesktopWidth = 766;

  private isMinDesktopWidth = new BehaviorSubject(false);
  isMinDesktopWidth$ = this.isMinDesktopWidth.asObservable().pipe(distinctUntilChanged());

  constructor(private deviceDetectorService: DeviceService) {}

  get isMobile(): boolean {

    return this._isMobile;
  }

  set isMobile(isMobile: boolean) {
    this._isMobile = isMobile;
  }

  get isTablet(): boolean {

    return this._isTablet;
  }

  set isTablet(isTablet: boolean) {
    this._isTablet = isTablet;
  }

  get isDesktop(): boolean {

    return this._isDesktop;
  }

  set isDesktop(isDesktop: boolean) {
    this._isDesktop = isDesktop;
  }

  get deviceInfo(): IDeviceInfo {

    return this._deviceInfo;
  }

  set deviceInfo(deviceInfo: IDeviceInfo) {
    this._deviceInfo = deviceInfo;
  }

  setWindowWidth(windowWidth: number): void {
    // apply mobile rules for a desktop with a small window width
    const isMinDesktopWidth = this.isDesktop && windowWidth < this.minDesktopWidth;
    this.isMinDesktopWidth.next(isMinDesktopWidth);
  }

  detectDevice = (): void => {
    this.isMobile = this.deviceDetectorService.isMobile();
    this.isTablet = this.deviceDetectorService.isTablet();
    this.isDesktop = this.deviceDetectorService.isDesktop();
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();

    const windowWidth = window.innerWidth
      || document.documentElement.clientWidth
      || document.body.clientWidth;

    this.setWindowWidth(windowWidth);
  }
}
