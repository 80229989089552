import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopUpContent } from '../../shared.interfaces';
import { CREATE_TICKET_DIALOG } from '../../constants';

@Component({
  selector: 'usp-popup',
  templateUrl: './usp-popup.component.html',
  styleUrls: ['./usp-popup.component.scss']
})
export class UspPopupComponent implements OnInit {

  constructor(
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: PopUpContent
  ) {
  }

  ngOnInit(): void {
  }

  onConfirmDialog(): void {
    if (this.data.confirmButton === CREATE_TICKET_DIALOG.CONFIRM_BUTTON) {
      window.scrollTo(0, 0);
      this.router.navigateByUrl('/');
    }
  }

}
