import { Component } from '@angular/core';
import { BusyIndicatorService } from './usp-busy-indicator.service';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'usp-busy-indicator',
  templateUrl: './usp-busy-indicator.component.html',
  styleUrls: ['./usp-busy-indicator.component.scss'],
})
export class BusyIndicatorComponent {
  isLoading = false;

  constructor(
    public busyIndicatorService: BusyIndicatorService
  ) {
    this.busyIndicatorService.isLoading.pipe(
      tap((isLoading) => this.isLoading = isLoading),
      catchError((error) => {
      return of();
    }));
  }
}
