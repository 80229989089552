<div class="usp-faqs-wrapper">
  <ds-message
    *ngIf="showErrorNotification"
    [message]="errorNotification"
    type="error"
    [closable]="true">
  </ds-message>

  <udex-tab-navigation
    *ngIf="FAQS_INFO.length"
    size-more-button="Medium"
    (click)="updateFaqsInfo($event)"
  >
    <udex-tab
      *ngFor="let faq of FAQS_INFO"
      text="{{faq?.category}}"
    >
      <usp-questions *ngIf="FAQS_INFO.length" [faqsInfo]="titles"></usp-questions>
    </udex-tab>
  </udex-tab-navigation>

  <usp-nav-tiles [navTile]="navTile"></usp-nav-tiles>
</div>
