import { Injectable } from '@angular/core';
import { Script } from 'src/app/shared/shared.interfaces';
import { environment } from 'src/environments/environment';


const ScriptStore: Script[] = [
  { name: 'lxpWebComponents', src: environment?.lxpWebComponentsLink },
];

declare var document: any;

@Injectable()
export class ScriptLoaderService {
  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src,
      };
    });
  }

  public load(...scripts: string[]): Promise<unknown[]> {

    return Promise.all(scripts.map((script) => this.loadScript(script)));
  }

  private loadScript(name: string): Promise<unknown> {

    return new Promise((resolve, reject) => {

      // Resolve if already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // Load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = this.scripts[name].src;
        if (script.readyState) {
          // IE
          script.onreadystatechange = () => {

            if (
              script.readyState === 'loaded' ||
              script.readyState === 'complete'
            ) {
              script.onreadystatechange = null;
              this.scripts[name].loaded = true;
              resolve({ script: name, loaded: true, status: 'Loaded' });
            }

          };
        } else {
          // Others
          script.onload = () => {
            this.scripts[name].loaded = true;
            resolve({ script: name, loaded: true, status: 'Loaded' });
          };
        }
        script.onerror = (error: any) =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        document.body.appendChild(script);
      }

    });
  }
}
